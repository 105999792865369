import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import './subcribePage.scss'
import "normalize.css/normalize.css"
import logo from "../images/BM_logos.gif"
import { Cookies } from "react-cookie-consent"
import { deleteAllCookies, useOptOut, optOut } from "../utils/analyticsDoNotTrack"
import { navigate } from "../../.cache/gatsby-browser-entry"


const IndexPage = () => {
  useEffect( () => {
    const consentGiven = Cookies.get('CookieConsent')
    console.log(consentGiven)
    if (consentGiven === false) {
      deleteAllCookies()
      optOut()
      navigate('/do-not-track')
    }
  });
  return (<Layout>
    <SEO title="BAD MANNERS"/>
    <div className="grid-container">
      <div className="image-wrapper">
        <img src={logo} className="image" alt="BAD MANNERS"/>
      </div>
      <div className="button-wrapper">
        <OutboundLink href="https://subscribe.newsletter2go.com/?n2g=yg969par-m69pb1s3-6ud">
          <button>
            subscribe
          </button>
        </OutboundLink>
        <OutboundLink href="https://shop.badmannersrecords.com/merch">
          <button>
            shop
          </button>
        </OutboundLink>
      </div>
      <div className="bottom">
        <Link to="/dataSecurity/">Data security</Link>
        <Link to="/imprint/">Imprint</Link>
      </div>
    </div>
  </Layout>)
}

export default IndexPage
